@import './mixins';

// Here you put all global css rules.
@font-face {
  font-family: "UnicaRegular";
  src: url("../images/Unica77LLWeb-Regular.eot");
  src: url("../images/Unica77LLWeb-Regular.eot?#iefix") format("embedded-opentype"), url("../images/Unica77LLWeb-Regular.woff2") format("woff2"), url("../images/Unica77LLWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "UnicaRegular";
  src: url("../images/Unica77LLWeb-Italic.eot");
  src: url("../images/Unica77LLWeb-Italic.eot?#iefix") format("embedded-opentype"), url("../images/Unica77LLWeb-Italic.woff2") format("woff2"), url("../images/Unica77LLWeb-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic
}

body {
  font: normal 24px/27px "UnicaRegular",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 0;
  color: $white;
  background-color: $black;
}
iframe {
  border: none;
}
a{
  color: $white;
  cursor: pointer;
}
* {
  box-sizing: border-box;
}
@include inputPlaceholder() {
  color: #fff;
}