@import '../../styles/mixins';

$w: 300px;
$h: 0.56 * $w;
$info: 55px;

.custom-channel {
  .end-date {
    font-size: 0.8rem;
    color: #999;
    text-transform: uppercase;
    margin: 0 10px;
    white-space: nowrap;
  }
}
.home-section-wrap{

  position: relative;
  .home-section {
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    margin-top: 10px;
    position: relative;
    height: $h + $info + 5px;
    .videos-wrap{
      display: flex;
      .video-wrap{
        position: relative;
        display: block;
        width: $w;
        height: $h + $info;
        flex-shrink: 0;
        padding-top: 0;
        margin-right: 10px;
        overflow: hidden;
        // padding-bottom: $info;
        .home-video{
          opacity: 0;
        }
        a.video-a{
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          transition: 0.1s background-size ease-out;
          display: block;
          width: $w;
          height: $h;
          position: relative;
          z-index: 3;
        }
        .info{
          position: absolute;
          top: $h;
          left: 0px;
          height: $info;
          width: $w;
          background-color: #000;
          opacity: 0;
          top: $h - $info;
          transition: 0.1s all ease-out;
          z-index: 1;
          display: flex;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          h3{
            font-size: 17px;
            margin: 11px 0 3px 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-style: italic;
            line-height: 1;
          }
          p{
            margin: 0px 0px 0px 15px;
            font-size: 0.8rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .details{
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          a.fav{
            cursor: pointer;
            width: 50px;
            flex-shrink: 0;
            flex-grow: 0;
            background: url(../images/star-outline.svg) no-repeat center 8px;
          }
          .caret{
            display: none;
          }
        }
        &.fav{
          &:before{
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0;
            height: 0;
            z-index: 4;
            border-style: solid;
            border-width: 50px 50px 0 0;
            border-color: #000 transparent transparent transparent;
          }
          &:after{
            content: '';
            top: 0px;
            left: 0px;
            width: 30px;
            height: 25px;
            display: block;
            position: absolute;
            z-index: 5;
            background: url(../images/star.svg) no-repeat center center;
            background-size: 17px;
          }
          .info a.fav{
            background-image: url(../images/star.svg);
            background-size: 19px;
          }
        }
        &.playing{
          .home-video{
            opacity: 1;
          }
        }
        &.hovered{
          a.video-a{
            background-size: 120%;
          }
          .info{
            opacity: 1;
            top: $h;
            // z-index: 1;
          }
        }
      }
      .home-video{
      }
    }
  }
  > a{
    position: absolute;
    right: 0;
    top: 0;
    width: 142px;
    height: $h;
    display: none;
    background: url(../images/right-arrow.svg) no-repeat center center;
    background-size: 40%;
    z-index: 5;
    &.left{
      right: auto;
      left: 0;
      background: url(../images/left-arrow.svg) no-repeat center center;
      background-size: 40%;
    }
  }
}
@media (hover: hover){
  .home-section-wrap{
    .home-section {
      .videos-wrap{
        .video-wrap{
          &:hover{
            a.video-a{
              background-size: 120%;
            }
            .info{
              opacity: 1;
              top: $h;
              // z-index: 1;
            }
          }
        }
      }
    }
    &:hover{
      > a {
        display: block;
      }
    }
  }
}
.home-section-wrap{
}
.section-wrap.interview,
.section-wrap.external{
  .info .details p{
    display: none;
  }

}

@media (max-width: $mobile-bp) {
  .home-section-wrap{
    $gap: 15px;
    .home-section {
      padding: 0px $gap;
      .videos-wrap{
        .group{
          position: relative;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          column-gap: $gap;
          row-gap: $gap;
          width: $w;
          height: $h + $info;
          flex-shrink: 0;
          padding-top: 0;
          margin-right: $gap;
          overflow: hidden;

          .video-wrap{
            height: auto;
            width: 100%;
            display: flex;
            a.video-a{
              height: auto;
              width: 100%;
              background-size: cover;
            }
          }
        }
        .video-wrap{
          margin-right: $gap;
          a.video-a{
            position: relative;
            height: $h + $info;
            background-size: cover;
            @include caret();
          }
          .info{
            p,
            h3{
              margin-left: 10px;
            }
            a.fav{
              width: 38px;
            }
          }
          &.hovered .info{
            top: auto;
            bottom: 0px;
            z-index: 4;
            width: 100%;
            .caret{
              position: absolute;
              bottom: 0px;
              right: 5px;
              display: block;
              width: 20px;
              height: 20px;
              background: url(../images/caret-up.svg) center right no-repeat;
              background-size: auto 40%;
              z-index: 4;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 756px) {
  .custom-channel {
    .end-date {
      white-space: wrap;
    }
  }
}
