@import '../../styles/mixins';

.home-video {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background: url(../images/ajax-loader.gif) center center no-repeat;
  .react-player{
    aspect-ratio: 16 / 9;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}
