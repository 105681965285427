@import '../../styles/mixins';

body.kadist-tv .home-welcome-page,
.home-welcome-page {
  padding-bottom: 50px;
  position: relative;
  &.loading{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1{
    text-align: center;
  }
  .login,
  .btn{
    border: 1px solid #fff;
    padding: 4px 20px 2px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    &:hover{
      background-color: #fff;
      color: #000;
    }
  }
  .controls, .video-duration {
    position: absolute;
    box-sizing: border-box;
    top: 0px;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    a {
      $hw: 45px;
      width: $hw;
      height: $hw;
      display: block;
      background-color: $white;
      border-radius: 100%;
      margin-left: 15px;
      background-position: center center;
      background-repeat: no-repeat;
      &.play{
        background-image: url(../images/play.svg);
        background-size: 100%;
        background-position-x: 3px;
        &.playing{
          background-image: url(../images/pause.svg);
          background-size: 34%;
          background-position-x: 14px;
        }
      }
      &.mute{
        background-image: url(../images/speaker-off.svg);
        background-size: 70%;
        background-position-x: 6px;
        &.muted{
          background-image: url(../images/speaker-on.svg);
          background-position-x: 10px;
        }
      }
      &.search{
        background-image: url(../images/search.svg);
        background-size: 45%;
      }
    }
    .search{
      display: flex;
      align-items: center;
      input{
        display: none;
      }
      &.open{
        > a{
          display: none;
        }
        input{
          margin-left: 5px;
          display: block;
          font-size: 1.4rem;
          border: none;
          padding: 5px 15px;
          &::placeholder{
            color: #fff;
          }
        }
      }
    }
  }
  .video-duration {
    padding: 2px;
  }
  &.hovered {
    .controls{
      opacity: 1;
    }
    a.prev, a.next {
      // animation: slide-in 0.5s forwards;
      opacity: 1;
    }
    .home-video-hero > header {
      opacity: 1;
    }
    .video-duration {
      opacity: 1;
    }
  }

  a.prev,
  a.next{
    position: absolute;
    right: 10px;
    bottom: 0;
    z-index: 3;
    color: $white;
    display: block;
    text-decoration: none;
    display: block;
    font-weight: 300;
    overflow: hidden;
    background: url(../images/right-arrow.svg) no-repeat center center;
    background-size: 40%;
    $h: 140px;
    height: $h;
    width: 120px;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    // transform: translateY(100%);
    &.prev{
      background-image: url(../images/left-arrow.svg);
      left: 10px;
      right: auto;
    }
  }
  nav.utility{
    position: sticky;
    top: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-size: 14px;
    background-color: #000;
    text-transform: uppercase;
    z-index: 22;
    > .filter-wrap,
    .o{
      display: none;
    }
    > .options-wrap,
    .o{
      display: none;
    }
    > * {
      margin: 0px 25px;
    }
    input{
      background: transparent;
      color: #fff;
      border: none;
      font-size: 14px;
      line-height: 1.2;
      border-bottom: 1px solid #fff;
      padding: 5px 5px 5px 0px;
      outline: none;
      width: 200px;
      &:focus::placeholder{
        color: #999;
      }
    }
  }
  .options-wrap, .filter-wrap{
    .select{
      display: inline-block;
      color: #fff;
      width: auto;
      .select__menu{
        background-color: black;
        width: 130px;

      }
      .select__placeholder{
        color: #fff;
        line-height: 1.3;
        position: relative;
        transform: none;
      }
      .select__option{
        line-height: 1.3;
        &.select__option--is-focused{
          color: #ccc;
          background-color: black;
        }
        &.select__option--is-selected{
          font-weight: bold;
          background-color: black;
        }
      }
      .select__control{
        background-color: transparent;
        border: none !important;
        box-shadow: none !important;
        &.select__control--is-focused{
          bordeR: none;
        }
      }
      .select__indicators,
      .select__indicator-separator{
        display: none;
      }
      .select__single-value{
        color: white;
      }
    }
    .a,
    a {
      display: inline-block;
      margin: 0px 15px;
      &.selected{
        border-bottom: 1px solid #fff;
      }
      &.option-favs{
        padding-left: 21px;
        background: url(../images/star.svg) no-repeat left 3px;
        background-size: 16px;
      }
    }
    &.region{
      .select{
        .select__placeholder{
          border-bottom: 1px solid #fff;
          position: relative;
          transform: none;
        }
      }
    }
  }

  h3{
    margin: 0px;
    padding: 0px;
    margin-left: 20px;
    font-size: 1.7rem;
    // max-width: 400px;
    font-weight: normal;
    &.favs-star {
    padding-left: 21px;
    background: url(../images/star.svg) no-repeat left 3px;
    background-size: 16px;
  }
  }
  .sections{
    .section-wrap{
      &:first-child{
        margin-top: 30px;
      }
    }
  }
  .section-wrap{
    padding: 10px 0px 0px;
    header{
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
    }
    .learn,
    .kvl-info{
      display: none;
    }
    .learn{
      display: inline-block;
      font-size: $text-size-normal - 2px;
      margin-left: 10px;
      line-height: 1;
      padding-bottom: 0px;
      &:before{
        content: 'LEARN MORE';
        border-bottom: 1px solid #fff;
      }
    }
    &.info{
      &.open{
        .learn{
          padding-right: 20px;
          background: url(../images/caret-up.svg) center right no-repeat;
          background-size: auto 70%;
          &:before{
            content: 'HIDE';
          }
        }
        .kvl-info{
          display: flex;
          font-size: $text-size-normal;
          padding: 0px 20px 20px 20px;
          width: 100%;
          padding-top: 20px;
          p{
            margin-top: 0;
            margin-bottom: 20px;
          }
          .content{
            flex: 1;
            padding-right: 40px;
          }
          .more-info{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .btn{
              margin-top: 20px;
            }
            > a {
              width: 170px;
              text-align: center;
            }
          }
        }
        &.logged-in{
          .more-info * {
            display: none;
          }
        }
      }
    }
  }
  .modal{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0px;
    lefT: 0px;
    .close{
      position: absolute;
      top: 0px;
      right: 0px;
      display: inline-block;
      padding: 30px;
      z-index: 11;
      &:before{
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        background: url(../images/close.svg) no-repeat center center;
        background-size: 50%;
        width: 100%;
        height: 100%;
        z-index: 11;
      }
      &:after{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 95px 95px 0;
        border-color: transparent $black transparent transparent;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 10;
      }
    }
    form{
      display: block;
      background-color: #000;
      padding: 50px;
      border: 1px solid #fff;
      width: 400px;
      position: relative;
      h3{
        padding: 50px 0 20px;
        text-align: center;
        margin-left: 0;
      }
      label{
        font-size: 18px;
        display: block;
        margin-top: 30px;
      }
      input[type="password"],
      input[type="text"]{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #fff;
        width: 100%;
        outline: transparent;
        color: #fff;
        font-size: $text-size-normal + 2px;
        padding: 4px 0px;
        &:focus{
          border-bottom-style: dotted;
        }
      }
      .btn{
        margin: 40px auto 0px;
        display: block;
        font-size: 18px;
        padding: 7px 35px;
      }
    }
    p{
      font-size: 16px;
      text-align: center;
      margin: 35px 0 0px 0;
    }
    &.video{
      padding: 10px 20px;
      background-color: rgba(0, 0, 0, 1);
      flex-direction: column;
      .home-video{
        max-height: calc(100vh - 60px);
        max-width: 100%;
      }
      .permalink{
        width: 100%;
        text-align: right;
        padding-top: 10px;
        font-size: 14px;
      }
    }
  }
}

@media(max-width: $mobile-bp){
  body.kadist-tv .home-welcome-page,
  .home-welcome-page {
    h3{
      margin-left: 10px;
      font-size: 1.4rem;
    }
    .learn{
      display: block !important;
      margin-left: 0 !important;
    }
    a.next,
    a.prev.prev{
      height: 80px;
      width: 60px;
      left: 0;
    }
    a.next{
      right: 0;
      left: auto;
    }
    nav.utility{
      padding: 0px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      top: 56px;
      .login{
        display: none;
      }
      &.open{
        > .options-wrap, > .filter-wrap{
          padding-top: 10px;
          grid-column: span 2;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }
        .search{
          border-bottom: 1px solid #fff;
        }
        .options{
          .o{
            &:before{
              transform: rotate(0deg);
            }
          }
        }
      }
      .search{
        box-sizing: border-box;
        margin: 0px;
        padding: 2px 10px 0px;
        border-right: 1px solid #fff;
        input[type=text]{
          width: 100%;
          border-bottom: none;
        }
      }
      .options{
        box-sizing: border-box;
        margin: 0px;
        padding: 2px 0px 0px 15px;
      }
      .options{
        display: flex;
        flex-wrap: wrap;
        &.not-all{
          .sort-by{
            display: none;
          }
        }
        .o{
          display: block;
          margin-left: 10px;
          margin-right: 0;
          overflow: hidden;
          flex-grow: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          // width: 90px;
          &:before{
            content: '';
            background: url(../images/caret-up.svg) center right no-repeat;
            background-size: auto 40%;
            top: 0px;
            right: 10px;
            width: 25px;
            height: 25px;
            z-index: 2;
            position: absolute;
            transform: rotate(180deg);
          }
        }
        .options-wrap, .filter-wrap{
          display: none;
        }
      }

    }
    .section-wrap{
      margin-top: 20px;
      header{
        position: relative;
        //&:after{
        //  content: 'Swipe for more';
        //  padding-right: 20px;
        //  right: 15px;
        //  position: absolute;
        //  text-transform: uppercase;
        //  background: url(../images/right-arrow.svg) no-repeat center right;
        //  background-size: 13px;
        //  top: 2px;
        //  font-size: 11px;
        //  font-weight: 400;
        //}
      }
    }
    .section-wrap.info.open .kvl-info .more-info{
      display: none;
    }
  }
}

