@import '../../styles/mixins';

body.kadist-tv .home-video-hero,
body .home-video-hero {
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  > header {
    transition: opacity 0.3s ease-out;
    opacity: 0;
    position: absolute;
    padding: 15px;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2;
    text-align: center;
    // transform: translateY(100%);
    background-color: rgba(0, 0, 0, 0.5);
    .read-more {
      font-size: 0.9rem;
    }
    .subcaption {
      p {
        font-size: 0.9rem;
        font-style: italic;
        margin: 5px 0 10px 4px;
        display: inline-block;
      }
    }
    h2{
      font-size: 2.2rem;
      font-weight: normal;
      line-height: 1;
      padding-bottom: 10px;
      margin-bottom: 0;
      margin-top: 25px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      a{
        border-bottom: 1px solid white;
        font-style: italic;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.pin{
          display: inline-block;
          padding-left: 10px;
          font-size: 20px;
          color: yellow;
          cursor: pointer;
        }
      }
    }
    p{
      font-size: 1.4rem;
      line-height: 1.4;
      margin: 5px 15px 15px;
    }
  }
  &.selected{
    opacity: 1;
  }
}
.carousel .slide .home-video-hero {
  .vimeo{
    width: 100%;
    display: flex;
  }
  iframe{
    width: 100%;
    aspect-ratio: 16 / 9;
    margin: 0;
  }
}
.description-reveal, .description-trunc {
  text-align: left;
}
.description-trunc {
    margin: 0 auto !important;
    width: 55%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  p {
    font-size: 1rem !important;
    padding: 0;
  }
  &.showing {
      -webkit-line-clamp: unset;
  }
}
.description-reveal {
  a {
    display: block;
    padding: 6px;
    border: 3px solid #fff;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 auto;
    max-width: 360px;
  }
}


@media(max-width: $mobile-bp){
  body.kadist-tv .home-video-hero,
  body .home-video-hero {
    > header{
      h2{
        font-size: 1rem;
        margin-top: 0;
      }
      p{
        margin: 0px;
        font-size: 0.9rem;
      }
    }
  }

}
