@keyframes slide-in {
  100% { transform: translateY(0%); }
}
@keyframes slide-down {
  100% { transform: translateY(100%); }
}
@mixin inputPlaceholder() {
    $selector: '';
    $prefixes: (
        moz: "::-moz",
        webkit: "::-webkit",
        ie: ":-ms"
    );
    @each $prop, $value in $prefixes {
        @if $prop != "moz" {
            $selector: #{$value}-input-placeholder;
        } @else {
            $selector: #{$value}-placeholder;
        }
        @if & {
            &#{$selector} {
                @content;
            }
        } @else {
            #{$selector} {
                @content;
            }
        }
    }
    &::placeholder {
      @content;
    }
}
@mixin caret() {
    &:before{
        content: '';
        position: absolute;
        bottom: 0px;
        right: 10px;
        display: block;
        width: 20px;
        height: 20px;
        background: url(../images/caret-up.svg) center right no-repeat;
        background-size: auto 40%;
        z-index: 4;
    }
}
.ellipses{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}