@import '../../styles/mixins';

@font-face {
	font-family: "UnicaRegular";
	src: url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Regular.eot");
	src: url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Regular.eot?#iefix") format("embedded-opentype"), url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Regular.woff2") format("woff2"), url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal
}

// @font-face {
// 	font-family: "UnicaRegular";
// 	src: url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Italic.eot");
// 	src: url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Italic.eot?#iefix") format("embedded-opentype"), url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Italic.woff2") format("woff2"), url("https://kadist.org/wp-content/themes/kadist/dist/fonts/Unica77LLWeb-Italic.woff") format("woff");
// 	font-weight: normal;
// 	font-style: italic
// }

.home-plain-carousel {
	font-family: "UnicaRegular";
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	.carousel-root,
	.carousel{
		width: 100%;
	}
	> header {
		position: fixed;
		z-index: 2;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 5rem;

	}
}
