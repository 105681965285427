@import '../../styles/mixins';

.home-video-tile {
  padding: 10px;
  text-align: center;
  .video-wrap{
    width: 300px;
    padding-bottom: 5px;
  }
  iframe{
    width: 100%;
    height: 182px;
  }

}
